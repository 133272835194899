import { children, Show } from 'solid-js';
import dayjs from '@troon/dayjs';
import { Picture } from './picture';
import { Link } from './link';
import type { ComponentProps, ParentProps } from 'solid-js';

type Props = ParentProps<{
	date?: Date | string | number;
	hero?: Omit<ComponentProps<typeof Picture>, 'width' | 'height'> & { width?: number; height?: number };
	title: string;
	url: string;
}>;

export function ArticleTile(props: Props) {
	const summary = children(() => props.children);
	return (
		<article class="group relative flex flex-col gap-2">
			<div class="overflow-hidden rounded">
				<Show when={props.hero}>
					{(image) => (
						<Picture
							alt=""
							crop="face"
							{...image()}
							width={image().width ?? 480}
							height={image().height ?? 270}
							loading={image().loading ? 'eager' : 'lazy'}
							sizes="(min-width: 1280px) 33vw, (min-width: 768px) 50vw, 95vw"
							class="size-full group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
						/>
					)}
				</Show>
			</div>
			<h1>
				<Link href={props.url} class="block text-lg font-semibold after:absolute after:inset-0">
					{props.title}
				</Link>
			</h1>
			<Show when={summary()}>
				<div class="[&_p]:line-clamp-2">{summary()}</div>
			</Show>
			<Show when={props.date}>
				{(date) => <time class="text-sm">{dayjs.tz(date(), 'America/Phoenix').format('MMMM D, YYYY')}</time>}
			</Show>
		</article>
	);
}
